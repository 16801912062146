<template>
  <div>
    <div v-show="this.$route.meta.showRole">
      <div class="condition">
        <div class="condition_item">
          <el-button class="button_default" @click="addFun(null, false)"
            >添加</el-button
          >
          <el-button class="import" @click="dialogVisible = true"
            >导入资产信息</el-button
          >
          <el-button type="danger" @click="remove(null)">删除</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
          stripe
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-row-class-name="tableRowClassName"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column label="客户名称">
            <template slot-scope="scope">{{ scope.row.customerName }}</template>
          </el-table-column>
          <el-table-column prop="fourCodeOrderList" label="四码合一">
            <template slot-scope="scope">
              <div class="fourCode">
                <span v-for="item in scope.row.fourCodeOrderList">
                  {{ item | fourCodeName }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="assetCode" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="edit el-icon-edit"
                :disabled="redactForbidden"
                size="small"
                @click="addFun(scope.row, true)"
                >编辑
              </el-button>
              <i class="app-operation-line" />
              <el-button
                type="text"
                size="small"
                style="color: #F56C6C;font-size: 14px"
                @click="remove(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="app-page-container">
        <span @click="first">首页</span>
        <el-pagination
          @current-change="handleCurrentChange"
          layout="pager"
          :hide-on-single-page="false"
          :page-size="12"
          :current-page="currentPage"
          :total="recordCount"
        >
        </el-pagination>
        <span @click="last">尾页</span>
      </div>
      <el-dialog
        title="请选择上传文件"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-upload
          :data="{ token: token, userId: userId }"
          class="upload-demo"
          accept=".xlsx,.xlsx"
          :on-error="errors"
          :show-file-list="true"
          :on-success="uploads"
          :action="url"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <!--                <el-button @click="dialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="uploading">关闭</el-button>
        </span>
      </el-dialog>
    </div>
    <router-view
      @getMessage="getMessage"
      :type="type"
      :compileData="compileData"
    ></router-view>
    <!--        <addition @getMessage="getMessage" :compileData="compileData" v-show="type == 'add'"></addition>-->
  </div>
</template>

<script>
import { deletes, getClientList } from "../../../api/AssetManagement";

export default {
  name: "PdaManage",
  components: {
    //注册组件
  },
  data() {
    return {
      dialogVisible: false,
      url: window.baseUrl + "/clodchainAM/web/import_assetCode",
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
      compileData: null,
      pages: 1,
      redactForbidden: false,
      type: "",
      pageSize: 12, //每页数据条数
      recordCount: 12,
      currentPage: 1, //当前页数
      tableData: [],
      multipleSelection: []
    };
  },
  created() {
    this.getLisr();
  },
  filters: {
    fourCodeName(val) {
      if (val == 1) {
        return "IMEI";
      } else if (val == 2) {
        return "设备编码";
      } else if (val == 3) {
        return "资产编码";
      } else if (val == 4) {
        return "RFID";
      } else if (val == 5) {
        return "资产贴纸";
      }
    }
  },
  methods: {
    errors(err, file, fileList) {
      if (err) {
        this.$message({
          showClose: true,
          message: "导入失败",
          type: "error"
        });
      }
    },
    uploading() {
      this.dialogVisible = false;
    },
    //导入回调
    uploads(response, file, fileList) {
      if (response.status == 200) {
        this.$message({
          showClose: true,
          message: "导入成功",
          type: "success"
        });
      }
      console.log(response);
      console.log(file);
      console.log(fileList);
    },
    handleClose(done) {
      done();
    },
    //拉取列表
    getLisr() {
      this.tableData = [];
      let data = { curPage: this.currentPage, limit: 12 };
      getClientList(data).then(res => {
        let data = res.data.data.records;
        this.recordCount = res.data.data.total;
        this.pages = res.data.data.pages;
        for (let i = 0; i < data.length; i++) {
          this.tableData.push(data[i]);
        }
      });
    },
    //接收子组件传值
    getMessage(data) {
      this.type = data;
      this.getLisr();
    },
    //添加功能
    addFun(data) {
      this.type = "add";
      this.compileData = data;
      this.$router.push("/dashboard/client/PDA/edit");
    },
    //编辑
    compile(data) {
      console.log(data);
    },
    //分页首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      let data = { curPage: 1, limit: 12 };
      getClientList(data).then(res => {
        let data = res.data.data.records;
        this.recordCount = res.data.data.total;
        this.pages = res.data.data.pages;
        for (let i = 0; i < data.length; i++) {
          this.tableData.push(data[i]);
        }
      });
    },
    //分页最后一页
    last() {
      this.tableData = [];
      let data = { curPage: this.pages, limit: 12 };
      this.currentPage = this.recordCount;
      getClientList(data).then(res => {
        let data = res.data.data.records;
        this.pages = res.data.data.pages;
        // this.recordCount = res.data.data.total;
        // console.log(data)
        for (let i = 0; i < data.length; i++) {
          this.tableData.push(data[i]);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getLisr();
    },
    //表格多选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length > 1) {
        this.redactForbidden = true;
      } else {
        this.redactForbidden = false;
      }
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex == 0) return "headerStyle";
    },
    //删除
    remove(data) {
      const that = this;
      function del() {
        let datas = {};
        if (data) {
          //单条删除
          datas = { ids: [data.customerId] };
        } else {
          //    多条删除
          datas = { ids: [] };
          for (let i = 0; i < that.multipleSelection.length; i++) {
            datas.ids.push(that.multipleSelection[i].customerId);
          }
        }
        deletes(datas, "/clodchainAM/web/delete_customer_info", "post").then(
          res => {
            if (res.data.resultCode == 0) {
              that.$message({
                showClose: true,
                message: "删除成功!",
                type: "success",
                onClose: () => {
                  that.getLisr();
                }
              });
            } else {
              that.$message.error(res.data.desc);
            }
          }
        );
      }
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        del();
      });
    }
  }
};
</script>

<style scoped>
.fourCode:hover {
  cursor: pointer;
}

.edit {
  font-size: 14px;
  color: #6e7790;
  margin-right: 10px;
}

.edit:hover {
  color: #0cc;
}

.el-icon-edit:hover {
  color: #0cc;
}

.button_default {
  color: #0cc;
  border-color: #99ebeb;
}

.button_default:hover {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}

.button_default:focus {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}

.condition {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

/*分页样式*/
.app-page-container {
  margin-top: 20px;
}

.app-page-container .el-pagination {
  display: inline-block;
}

.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}

.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}

/*表格换行样式*/
/deep/ .el-table .cell {
  white-space: pre-line;
}

/*/deep/ .el-table td, .el-table th{*/
/*    padding: 0;*/
/*}*/
</style>
